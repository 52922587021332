import { computed, ref } from 'vue';
import { defineStore, storeToRefs } from 'pinia';
import { useRoute } from 'vue-router';
import { format } from 'date-fns';

import useEmailStore from '@/stores/agent/agent-emails';
import useLocationStore from '@/stores/agent/agent-locations';

import { useApi } from '@/composables/useApi';

export default defineStore('agent/appt-scheduler', () => {
	const ENABLE_APPT_SCHEDULER_FOR_ALL_AGENTS = ref(false);

	const VueRoute = useRoute();
	const emailStore = useEmailStore();
	const locationStore = useLocationStore();
	const { agentLocations } = storeToRefs(locationStore);

	const appointments = ref([]);
	const schedules = ref([]);
	const activeScheduleID = ref(null);
	const hasAS = ref(false);
	const enablingAS = ref(false);
	const disablingAS = ref(false);
	const loadingAppointments = ref(false);
	const loadingSchedules = ref(false);
	const loadingCoverage = ref(false);
	const loadingOOOBlocks = ref(false);
	const savingChangesTimeout = ref(null);
	const numberOfChangesBeingSaved = ref(0);
	const saveStatusRef = ref('saving');
	const savingChanges = ref(false);
	const agencyWideCalendars = ref(null);
	const subscriptionJustEnabled = ref(false);
	const subscriptionJustCanceled = ref(false);
	const emailRecipients = ref([]);

	const activeSchedule = computed(
		() => schedules.value.find(schedule => schedule.id === activeScheduleID.value) ?? {}
	);
	const saveStatus = computed({
		get() {
			return saveStatusRef.value;
		},
		set(newValue) {
			clearTimeout(savingChangesTimeout.value);
			if (newValue === 'saved') {
				if (--numberOfChangesBeingSaved.value === 0) {
					saveStatusRef.value = 'saved';
				}
				savingChangesTimeout.value = setTimeout(() => {
					savingChanges.value = false;
				}, 3000);
			} else if (newValue === 'saving') {
				numberOfChangesBeingSaved.value++;
				saveStatusRef.value = 'saving';
				savingChanges.value = true;
			}
		},
	});
	const calendarSubscriptions = computed(() => {
		const calendarSubscriptions = [];
		for (const schedule of schedules.value) {
			calendarSubscriptions.push({ label: schedule.label, calendars: schedule.calendars });
		}
		if (agencyWideCalendars.value) {
			calendarSubscriptions.push({
				label: 'Agency-Wide',
				calendars: agencyWideCalendars.value,
			});
		}
		return calendarSubscriptions;
	});

	async function getAS(agentId = VueRoute.params.agentID) {
		if (agentId !== '0XDEADBEEFD' && !ENABLE_APPT_SCHEDULER_FOR_ALL_AGENTS.value) {
			this.$reset;
			return;
		}

		const { data } = await useApi(`api/agents/${agentId}/scheduler_subscription/`, {
			message: `There was an error getting your appointment scheduler subscription. Please try again later.`,
		}).json();

		hasAS.value = data?.value?.active;
	}

	async function enableAS() {
		enablingAS.value = true;
		await useApi(`api/agents/${VueRoute.params.agentID}/scheduler_subscription/enable/`, {
			message: `There was an error signing up for appointment scheduling. Please try again later.`,
		}).put();
		subscriptionJustEnabled.value = true;
		subscriptionJustCanceled.value = false;

		getAS();
		emailStore.ensureEmails();
		await getSchedules();
		if (!schedules.value.length) {
			await createSchedule();
		}

		enablingAS.value = false;
	}

	async function disableAS() {
		disablingAS.value = true;
		await useApi(`api/agents/${VueRoute.params.agentID}/scheduler_subscription/disable/`, {
			message: `There was an error canceling your appointment scheduling. Please try again later.`,
		}).put();
		subscriptionJustEnabled.value = false;
		subscriptionJustCanceled.value = true;
		await getAS();
		disablingAS.value = false;
	}

	async function getAppointments() {
		loadingAppointments.value = true;
		const { data, statusCode } = await useApi(
			`api/agents/${VueRoute.params.agentID}/appointments/`,
			{
				message: `There was an error processing the request. Please try again later.`,
			}
		).json();

		if (statusCode.value === 200) {
			appointments.value = data.value.map(appt => ({
				...appt,
				assigned_to_name: appt.assigned_to.name,
				location_name: appt.location?.office_location.city ?? 'Virtual',
				status: appt.status ?? 'scheduled',
				date: format(new Date(appt.start_at), `iiiiii MMM dd, yyy`),
			}));
		}
		loadingAppointments.value = false;
	}

	async function cancelAppointment(id) {
		await useApi(`api/agents/${VueRoute.params.agentID}/appointments/${id}/`, {
			message: `There was an error canceling that appointment. Please try again later.`,
		}).delete();
		await getAppointments();
	}

	async function rescheduleAppointment(id, newData) {
		await useApi(`api/agents/${VueRoute.params.agentID}/appointments/${id}/reschedule/`, {
			message: `There was an error rescheduling that appointment. Please try again later.`,
		}).post(newData);
		await getAppointments();
	}

	async function getSchedules() {
		loadingSchedules.value = true;
		const { data, statusCode } = await useApi(
			`api/agents/${VueRoute.params.agentID}/schedules/`,
			{
				message: `There was an error getting your availability schedules. Please try again later.`,
			}
		).json();
		if (statusCode.value === 200) {
			activeScheduleID.value ??= data.value[0]?.id.toString();
			schedules.value = data.value.map(schedule => ({
				...schedule,
				id: schedule.id.toString(),
				availability: schedule.availability.map(a => ({
					...a,
					start: a.start.slice(0, -3),
					end: a.end.slice(0, -3),
					recurrences: convertRRuleToSimpleArray(a.recurrences),
				})),
			}));
		}
		loadingSchedules.value = false;
	}

	async function createSchedule() {
		saveStatus.value = 'saving';
		loadingSchedules.value = true;

		const uid = crypto.randomUUID().slice(0, 3);
		const newSchedule = {
			agent: VueRoute.params.agentID,
			label: `New Schedule ${uid}`,
			domains: [],
		};

		const { statusCode } = await useApi(`api/agents/${VueRoute.params.agentID}/schedules/`, {
			message: `There was an error creating your schedule. Please try again later.`,
		}).post(newSchedule);

		if (statusCode.value === 201) {
			saveStatus.value = 'saved';
			await getSchedules();
			activeScheduleID.value = schedules.value.at(-1).id;
		} else {
			savingChanges.value = false;
		}
		loadingSchedules.value = false;
	}

	async function updateSchedule(idToUpdate, newData) {
		saveStatus.value = 'saving';

		const scheduleIndexToUpdate = schedules.value.findIndex(s => s.id === idToUpdate);
		const originalSchedule = schedules.value[scheduleIndexToUpdate];
		const udpatedSchedule = {
			...originalSchedule,
			...newData,
		};
		schedules.value.splice(scheduleIndexToUpdate, 1, udpatedSchedule);

		const { statusCode } = await useApi(
			`api/agents/${VueRoute.params.agentID}/schedules/${idToUpdate}/`,
			{
				message: `There was an error updating your schedule. Please try again later.`,
			}
		).put(udpatedSchedule);

		if (statusCode.value === 200) {
			saveStatus.value = 'saved';
		} else {
			schedules.value.splice(scheduleIndexToUpdate, 1, originalSchedule);
			savingChanges.value = false;
		}
	}

	async function deleteSchedule(scheduleIdToDelete = activeScheduleID.value) {
		saveStatus.value = 'saving';
		schedules.value = schedules.value.filter(s => s.id !== scheduleIdToDelete);
		activeScheduleID.value = schedules.value[0]?.id;

		const { statusCode } = await useApi(
			`api/agents/${VueRoute.params.agentID}/schedules/${scheduleIdToDelete}/`,
			{
				message: `There was an error deleting your schedule. Please try again later.`,
			}
		).delete();

		if (statusCode.value === 204) {
			saveStatus.value = 'saved';
		} else {
			await getSchedules();
			savingChanges.value = false;
		}
	}

	async function getCoverage() {
		loadingCoverage.value = true;
		const { data, statusCode } = await useApi(
			`api/agents/${VueRoute.params.agentID}/schedules/${activeScheduleID.value}/`,
			{
				message: `There was an error retrieving your schedule's "COVERAGE VIEW" data. Please try again later.`,
			}
		).json();
		if (statusCode.value === 200) {
			activeSchedule.value.coverage = data?.value.coverage;
		}
		loadingCoverage.value = false;
	}

	async function getAvailabilityBlocks(scheduleID = activeScheduleID.value) {
		const { data, statusCode } = await useApi(
			`api/agents/${VueRoute.params.agentID}/schedules/${activeScheduleID.value}/availability/`,
			{
				message: `There was an error getting your availability blocks. Please try again later.`,
			}
		).json();

		if (statusCode.value === 200 && numberOfAvailabilityBlocksBeingCreated <= 1) {
			const scheduleIndexToUpdate = schedules.value.findIndex(s => s.id === scheduleID);
			schedules.value[scheduleIndexToUpdate].availability = data?.value.map(aBlock => {
				return {
					...aBlock,
					start: aBlock.start.slice(0, -3),
					end: aBlock.end.slice(0, -3),
					recurrences: convertRRuleToSimpleArray(aBlock.recurrences),
				};
			});
		}
	}

	let numberOfAvailabilityBlocksBeingCreated = 0;
	async function createAvailabilityBlock(associateId) {
		numberOfAvailabilityBlocksBeingCreated++;
		saveStatus.value = 'saving';

		let agentAssociateId = null;
		let staffAssociateId = null;
		if (associateId === VueRoute.params.agentID) {
			agentAssociateId = associateId;
		} else {
			staffAssociateId = associateId;
		}

		const newAvailabilityBlock = {
			staff: staffAssociateId,
			agent: agentAssociateId,
			start: '09:00',
			end: '17:00',
			recurrences: [false, true, true, true, true, true, false],
			meeting_lengths: [15, 30],
			communication_mediums: ['in person', 'phone'],
			location: agentLocations.value[0].office_associate_id,
			schedule: activeScheduleID.value,
		};
		activeSchedule.value.availability = [
			...activeSchedule.value.availability,
			newAvailabilityBlock,
		];

		const serializedAvailabilityBlock = {
			...newAvailabilityBlock,
			recurrences: 'RRULE:FREQ=WEEKLY;BYDAY=MO,TU,WE,TH,FR',
		};

		const { statusCode } = await useApi(
			`api/agents/${VueRoute.params.agentID}/schedules/${activeScheduleID.value}/availability/`,
			{
				message: `There was an error creating your availability block. Please try again later.`,
			}
		).post(serializedAvailabilityBlock);

		if (statusCode.value === 201) {
			saveStatus.value = 'saved';
			getCoverage();
		} else {
			savingChanges.value = false;
		}

		await getAvailabilityBlocks().then(() => {
			numberOfAvailabilityBlocksBeingCreated--;
		});
	}

	async function updateAvailabilityBlock(availabilityBlockID, newData) {
		saveStatus.value = 'saving';

		const originalAvailabilityBlock = activeSchedule.value.availability.find(
			ab => ab.id === availabilityBlockID
		);
		const updatedAvailabilityBlock = {
			...originalAvailabilityBlock,
			...newData,
		};
		activeSchedule.value.availability.splice(
			activeSchedule.value.availability.findIndex(ab => ab.id === availabilityBlockID),
			1,
			updatedAvailabilityBlock
		);

		const serializedAvailabilityBlock = {
			...updatedAvailabilityBlock,
			recurrences: convertRecurrenceArrayToRRule(updatedAvailabilityBlock.recurrences),
		};
		const { statusCode } = await useApi(
			`api/agents/${VueRoute.params.agentID}/schedules/${activeScheduleID.value}/availability/${availabilityBlockID}/`,
			{
				message: `There was an error updating your availability block. Please try again later.`,
			}
		).put(serializedAvailabilityBlock);

		if (statusCode.value === 200) {
			saveStatus.value = 'saved';
			if (['recurrences', 'start', 'end'].some(key => Object.keys(newData).includes(key))) {
				getCoverage();
			}
		} else {
			savingChanges.value = false;
			activeSchedule.value.availability.splice(
				activeSchedule.value.availability.findIndex(ab => ab.id === availabilityBlockID),
				1,
				originalAvailabilityBlock
			);
		}
	}

	async function deleteAvailabilityBlock(id) {
		saveStatus.value = 'saving';
		activeSchedule.value.availability = activeSchedule.value.availability.filter(
			ab => ab.id !== id
		);

		const { statusCode } = await useApi(
			`api/agents/${VueRoute.params.agentID}/schedules/${activeScheduleID.value}/availability/${id}/`,
			{
				message: `There was an error deleting your availability block. Please try again later.`,
			}
		).delete();
		if (statusCode.value === 204) {
			saveStatus.value = 'saved';
			getCoverage();
		} else {
			savingChanges.value = false;
			getAvailabilityBlocks();
		}
	}

	async function getOOOBlocks() {
		loadingOOOBlocks.value = true;
		const { data } = await useApi(
			`api/agents/${VueRoute.params.agentID}/schedules/${activeScheduleID.value}/out_of_office_blocks/`,
			{
				message: `There was an error getting your out of office blocks. Please try again later.`,
			}
		).json();
		loadingOOOBlocks.value = false;
		activeSchedule.value.out_of_office_blocks = data.value;
	}

	async function createOOOBlock(associateIds) {
		saveStatus.value = 'saving';
		loadingOOOBlocks.value = true;
		const newOOOBlock = {
			staff: activeSchedule.value.staff,
			start: '9:00:00',
			end: '17:00:00',
			date: new Date().toISOString().split('T')[0],
			schedule: activeScheduleID.value,
			associate_ids: associateIds,
			repeats_annually: false,
		};
		const { statusCode } = await useApi(
			`api/agents/${VueRoute.params.agentID}/schedules/${activeScheduleID.value}/out_of_office_blocks/`,
			{
				message: `There was an error creating your out of office block. Please try again later.`,
			}
		).post(newOOOBlock);
		if (statusCode.value === 201) {
			saveStatus.value = 'saved';
			await getOOOBlocks();
		} else {
			savingChanges.value = false;
			loadingOOOBlocks.value = false;
		}
	}

	async function updateOOOBlock(oooBlockID, newData) {
		saveStatus.value = 'saving';
		loadingOOOBlocks.value = true;
		const { statusCode } = await useApi(
			`api/agents/${VueRoute.params.agentID}/schedules/${activeScheduleID.value}/out_of_office_blocks/${oooBlockID}/`,
			{
				message:
					'There was an error updating your out of office block. Please try again later.',
			}
		).put(newData);
		if (statusCode.value === 200) {
			saveStatus.value = 'saved';
			await getOOOBlocks();
		} else {
			savingChanges.value = false;
			loadingOOOBlocks.value = false;
		}
	}

	async function deleteOOOBlock(oooBlockID) {
		saveStatus.value = 'saving';
		loadingOOOBlocks.value = true;
		const { statusCode } = await useApi(
			`api/agents/${VueRoute.params.agentID}/schedules/${activeScheduleID.value}/out_of_office_blocks/${oooBlockID}/`,
			{
				message:
					'There was an error deleting your out of office block. Please try again later.',
			}
		).delete();
		if (statusCode.value === 204) {
			saveStatus.value = 'saved';
			await getOOOBlocks();
		} else {
			savingChanges.value = false;
			loadingOOOBlocks.value = false;
		}
	}

	async function getAgencyWideCalendars() {
		const { data, statusCode } = await useApi(
			`api/agents/${VueRoute.params.agentID}/calendars/`,
			{
				message:
					'There was an error getting your calendar subscription links. Please try again later.',
			}
		).json();
		if (statusCode.value === 200) {
			agencyWideCalendars.value = data.value;
		}
	}

	async function getEmailRecipients() {
		const { data, statusCode } = await useApi(
			`api/agents/${VueRoute.params.agentID}/appointment_notification_recipients/`,
			{
				message:
					'There was an error getting your email recipients. Please try again later.',
			}
		).json();
		if (statusCode.value === 200) {
			emailRecipients.value = data.value.recipients;
		}
	}

	async function updateEmailRecipients(newData) {
		saveStatus.value = 'saving';
		const { statusCode } = await useApi(
			`api/agents/${VueRoute.params.agentID}/appointment_notification_recipients/`,
			{
				message:
					'There was an error updating your email recipients. Please try again later.',
			}
		).patch(newData);
		if (statusCode.value === 204) {
			saveStatus.value = 'saved';
		} else {
			savingChanges.value = false;
		}
	}

	function convertRRuleToSimpleArray(rrule) {
		const weeklyAvailability = [false, false, false, false, false, false, false];
		const daysMap = { SU: 0, MO: 1, TU: 2, WE: 3, TH: 4, FR: 5, SA: 6 };

		const bydayMatch = rrule.match(/BYDAY=([A-Z,]+)/);
		if (bydayMatch) {
			const days = bydayMatch[1].split(',');
			days.forEach(day => {
				const index = daysMap[day];
				if (index !== undefined) {
					weeklyAvailability[index] = true;
				}
			});
		}
		return weeklyAvailability;
	}

	function convertRecurrenceArrayToRRule(recurrenceArray) {
		if (!recurrenceArray) {
			return '';
		}
		if (!recurrenceArray.includes(true)) {
			return '';
		}
		let rruleString = 'RRULE:FREQ=WEEKLY;BYDAY=';
		const days = ['SU', 'MO', 'TU', 'WE', 'TH', 'FR', 'SA'];
		const daysToInclude = [];
		for (let i = 0; i < recurrenceArray.length; i++) {
			if (recurrenceArray[i]) {
				daysToInclude.push(days[i]);
			}
		}
		rruleString += daysToInclude.join(',');
		return rruleString;
	}

	return {
		ENABLE_APPT_SCHEDULER_FOR_ALL_AGENTS,

		activeScheduleID,
		activeSchedule,
		agencyWideCalendars,
		calendarSubscriptions,
		emailRecipients,

		hasAS,
		enablingAS,
		disablingAS,
		getAS,
		enableAS,
		disableAS,
		subscriptionJustEnabled,
		subscriptionJustCanceled,

		getAppointments,
		loadingAppointments,
		appointments,
		cancelAppointment,
		rescheduleAppointment,

		getSchedules,
		loadingSchedules,
		loadingCoverage,
		schedules,
		createSchedule,
		updateSchedule,
		deleteSchedule,

		getAvailabilityBlocks,
		createAvailabilityBlock,
		updateAvailabilityBlock,
		deleteAvailabilityBlock,

		getOOOBlocks,
		loadingOOOBlocks,
		createOOOBlock,
		updateOOOBlock,
		deleteOOOBlock,

		getAgencyWideCalendars,
		getEmailRecipients,
		updateEmailRecipients,

		saveStatus,
		savingChanges,
	};
});
